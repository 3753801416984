import React, { FunctionComponent } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

interface Props {
  alt?: string;
  className?: string;
  fileName: string;
}

const Image: FunctionComponent<Props> = (props: Props) => {
  const { alt, className, fileName } = props;

  const data = useStaticQuery(graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 2560) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  for (let i = 0; i < data.images.edges.length; i += 1) {
    if (data.images.edges[i].node.relativePath === fileName) {
      return (
        <Img
          alt={alt}
          className={className}
          fluid={data.images.edges[i].node.childImageSharp.fluid}
        />
      );
    }
  }

  return null;
};

export default Image;
